import React, { useState, useEffect, useContext } from "react";
import { Link , Navigate, useNavigate, useLocation, useParams } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import Alert from '../../components/Alerts/Alert';
import FeedBackAlert from '../../components/Alerts/SuccessAlert';

import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import SizeAndMeLogo from '../../assets/svg/size_text_logo';
export default function Login() {
  const { verifyKey } = useParams();
  console.log(verifyKey)
  const { t, i18n } = useTranslation();
 
  const navigate = useNavigate();
/*   const location = useLocation();
  const from = location.state?.from?.pathname || "/"; */

  const [showAlert, setShowAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorText, setErrorText] = useState('')
  const [error, setError] = useState(true);
  const [cookies, setCookie] = useCookies(['name']);
  const [disabled, setDisabled] = useState(false)
 

    
  const onSubmit = async (values) => {
    let token =''
    setError(true)
    if (values.password !== values.verifyPassword) {
      setErrorText(t('passwordmustmatch'))
      setShowAlert(true)
      return
                    
    }
    else if (values.password.length < 7) {
      setErrorText(t('passwordmustmatch1'))
      setShowAlert(true)
      return
    }
    else if (
      !values.password.match(/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/) ) {
        setErrorText(t('passwordmustmatch2'))
        setShowAlert(true)
        return

    }
    
  
    try {  
      const response = await axios.post(`/api/verify/${verifyKey}`, values)
      const cleanResponse = response.data;
      /* if (cleanResponse === "Success") { */
     
        setErrorText(t('passwordsuccess'))
        setShowSuccess(true)
        setDisabled(true)
        return
      /* } */ /* else {
        setErrorText(t('passwordfail'))
        setShowAlert(true)
        return

      } */
  
     
    
  } catch (err) {
    setError(true)
    if (!err?.response) {
      setErrorText(t('logerr1'))
    } else if (err.response?.status === 400) {
      setErrorText(t('logerr2'))
    } else if (err.response?.status === 401) {
      setErrorText(t('logerr3'))
    } else {
      setErrorText(t('logerr4'))
    }
    setShowAlert(true)
    
  
  }
}

  return (
    <>
      <div className="container mx-auto px-4 h-full min-h-screen flexEvenly" >
        <div className="flex content-center items-center justify-center h-full">
       
          <div className="w-full lg:w-6/12 px-4 ">
      
            <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg  bg-white border-0 flex-row" style={{display: "flex", flexDirection: "row"}}>
     
              <div className="w-full ">
            
            
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-8">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                 {/*  <small>Or sign in with credentials</small> */}
                </div>
                <Formik initialValues={{password: "", verifyPassword: ""}} onSubmit={(values, { resetForm }) => 
                {
                  
                  onSubmit(values);
                  resetForm()
                }}>
                <Form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-black text-xs  mb-2 textBlack"
                      htmlFor="grid-password"
                    >
                       {t('log2')} 
                    </label>
                    <Field
                      type="password"
                      name="password"
                      className=" textBlack inputBlack px-3 py-3 placeholder-black-300 text-black-600 bg-white  text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className=" textBlack block uppercase text-black text-xs  mb-2"
                      htmlFor="grid-password"
                    >
                     {t('log3')}
                    </label>
                    <Field
                      type="password"
                      name="verifyPassword"
                      className="  textBlack inputBlack  px-3 py-3 placeholder-black-300 text-black-600 bg-white  text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                  <div>
                    {/* <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear border border-bluegray-300 transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label> */}
                  </div>

                  <div className="text-center mt-3">
                    <button
                      className=" buttonBlack text-white shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                      disabled={disabled}
                    >
                      {t('log1')}
                    </button>
                  </div>
                 
                </Form>
                </Formik>
                
              </div>
              
            </div>
          {/*   <div className="w-full lg:w-6/12 px-4">
            <img src="https://sizemeecommerce.s3.eu-west-3.amazonaws.com/sizeandmelogo.svg" width="200px" id='canvasloginLogo' alt="sizeandmelogo" /> */}
           {/*  <canvas id="gradient-canvaslogin"> </canvas> */}
          {/*   </div> */}
            
        
            </div>
            
          </div>
          
         
        </div>
       <div className="descriptionBottom">
        <span className="textDescriptionWhite">{t('text_1')} <a href="mailto: info@sizeandme.com" style={{textDecoration: "underline"}}>{t('contactus')}</a></span>
        <SizeAndMeLogo />
        <p className="textDescriptionGray">{t('text_2')}</p>
        <div className="descriptionBottom__socialmedia">
            <a href="https://twitter.com/sizeandme" target="_blank" rel="noreferrer"  className="descriptionBottom__socialmedia--button">
              Twitter
            </a>
            <a href="https://instagram.com/sizeandme" target="_blank" rel="noreferrer"  className="descriptionBottom__socialmedia--button">
              Instagram
            </a>
            <a href="https://www.linkedin.com/company/sizeandme/" target="_blank" rel="noreferrer"  className="descriptionBottom__socialmedia--button">
              Linkedin
            </a>
        </div>
       </div>
       
      {/*   <canvas id="gradient-canvaslogin"></canvas> */}
      </div>
      
      {showAlert === true ? <Alert  text={errorText} close={setShowAlert} /> : "" } 
      {showSuccess === true ? <FeedBackAlert  setShowSuccess={setShowSuccess}  text={errorText}/> : null}
    </>
  );
}
