import React from "react";
import { Routes, Route, Navigate} from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import ResetPassword from "views/auth/ResetPassword.js";
import Expired from "views/auth/Expired.js"

export default function Auth() {
  return (
    <>
    {/*   <Navbar transparent /> */}
      <main style={{background: "black"}}>
        <section className="relative w-full h-full min-h-screen">
        
          <Routes>
            <Route path="/signUp/:verifyKey" element={<Login />} />
            <Route path="/forgotPassword/:verifyKey" element={<ResetPassword />} />
            <Route path="/feedback" element={<Expired />} />
            <Route
        path="/"
        element={<Navigate to="/signUp" />}
    />
 </Routes>
      
         {/*  <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
}
